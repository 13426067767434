const fixture = [
    {
        programName: "Permissioning™ Manifesting",
        image: "/app/images/permission-bonus/manifesting.jpg",
        tags: ['quantum-touch'],
        type: 'single',
        title: "Permissioning™ Manifesting",
        duration: "131",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/manifesting-22042020.m4a"
    }
] 
export default fixture