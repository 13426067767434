import React, { useState } from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import {
  StyledProductPrimaryCard,
  ProductHeader,
  ProductExcerpt,
  ProductFigure,
  ProductFigureCaption
} from "./StyledPrimaryProductCard"
import { StyledModal,StyledModalHeader } from "../../styledComponents/modal"
import Icon from "../icon"
import KarinaGrantLogo from "../logo"
import { StyledButton, StyledCloseModalButton } from "../../styledComponents/button"
import permissionResetNervousSystem from "../../fixtures/permission-to-reset-nervous-system-online"
import SpoonTherapyVideo from "../spoonTherapyVideo"
import FmtjHealingTuningBlueprintProgram from "../fmtjHumaTuningBlueprint"

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  let collection 
  if(data.id == 'cG9zdDo3OTI5') collection = permissionResetNervousSystem

  const image = {
    imageData: data.image,
    alt: ``,
    description: "",
  }
  
  return (
      <>
       <StyledProductPrimaryCard
        itemScope
        itemType="http://schema.org/Product"
        onClick={toggleModal}
      >
        {image && image.imageData && (
              <ProductFigure>
                <img
                  src="/images/human-tuning-blueprint-min.jpg"
                  alt={image.alt}
                  width="100%"
                  padding-bottom="0" />
                <ProductFigureCaption>{image.description}</ProductFigureCaption>
              </ProductFigure>
            )}
        <ProductHeader itemProp="name">{data.title}</ProductHeader>
        <ProductExcerpt>{parse(data.excerpt)}</ProductExcerpt>
        <StyledButton className="listen-now">Listen</StyledButton>
      </StyledProductPrimaryCard>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        // className="audio-list-modal"
        className='audio-list-modal'
      >
        <StyledModalHeader type={collection[0].type}>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
          </div>
        </StyledModalHeader>
        <FmtjHealingTuningBlueprintProgram />
        </StyledModal>
      </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const FtmjHealingTuningBlueprint = props => {
  const { 
    id,
    featuredImage, 
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (
    
    <>
    <ModalProvider backgroundComponent={FadingBackground}>
             <FancyModalButton 
               id={props.id} 
               title={props.title} 
               excerpt={props.excerpt} 
               image='/images/human-tuning-blueprint-min.jpg'
               price={props.pricefull}
             />
           </ModalProvider>
       </>
  )
}

export default FtmjHealingTuningBlueprint