import React from "react"
import {
  Section,
} from "../styledComponents/section"
import permissionResetNervousSystemJuly2023 from "../fixtures/permission-to-reset-nervous-system-online-july-2023"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
import VideoPermissionResetNervousSystemCard from "./App/videoPermissionResetNervousSystemCard"
const PermissionResetNervousSystemJuly2023Audio = () => {
  const sleep = permissionResetNervousSystemJuly2023
    return (
        <>
          <Section>
            <StyledAudio type="program">
              {
                sleep[0].audioFiles.map(audio => {
                  return (
                    <VideoPermissionResetNervousSystemCard 
                      key={audio.title + `-1`}
                      title={audio.title}
                      image={audio.image}
                      content={audio.content}
                    />     
                  )
                })
              }
            </StyledAudio>
          </Section>
        </>
    )
}

export default PermissionResetNervousSystemJuly2023Audio