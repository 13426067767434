import React, { useState } from "react"
import styled from "styled-components"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import {
  StyledProductPrimaryCard,
  ProductHeader,
  ProductExcerpt,
  ProductFigure,
  ProductFigureCaption
} from "./StyledPrimaryProductCard"
import Icon from "../icon"
import { analyticClickEvent } from "../googleAnalytics"
import { StyledButton } from "../../styledComponents/button"
import permissionResetNervousSystem from "../../fixtures/permission-to-reset-nervous-system-online"
import { StyledAudioModal, StyledAudioModalCloseButton, StyledSingleAudioBody, StyledSingleAudioHeader, StyledSingleAudioTitle } from "../../styledComponents/app/audioCard"
import { ReactAudioPlayer } from "../reactPlayer"

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);
  
  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function toggleModalClose(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
    let action
    if(identity.user){
      action = 'UserID: ' + identity.user.id + ' Closes'
    }
    else {
      action = 'UserID: NOT LOGGED IN Closes'
    }
    analyticClickEvent(action,'Engagement', data.audio.title, '')
  }
  
  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }
  
  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }
  
  const identity = useIdentityContext()
  let action
  if(identity.user){
    action = 'UserID: ' + identity.user.id + ' Watches'
  } else {
    action = 'UserID: NOT LOGGED IN Watches'
  }

  let collection
  if (data.id == 'cG9zdDo3OTI5') collection = permissionResetNervousSystem

  const image = {
    imageData: data.image,
    alt: ``,
    description: "",
  }

  return (
    <>
      <StyledProductPrimaryCard
        itemScope
        itemType="http://schema.org/Product"
        onClick={toggleModal}
      >
        {image && image.imageData && (
          <ProductFigure>
            <img
              src="/images/morning-daily-intention.jpg"
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ProductFigureCaption>{image.description}</ProductFigureCaption>
          </ProductFigure>
        )}
        <ProductHeader itemProp="name">Morning Daily Intention</ProductHeader>
        <ProductExcerpt>Start your morning with a personal daily intention that will set you up for a mindful day.</ProductExcerpt>
        <StyledButton className="listen-now" onClick={toggleModal}>Listen</StyledButton>
      </StyledProductPrimaryCard>
      
        <StyledAudioModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          backgroundImage={data.image}
          colourOverlay='red'
          className="single-audio-modal"
        >
          <StyledSingleAudioHeader>
            <StyledAudioModalCloseButton onClick={toggleModalClose}>
              <Icon name="cross" />
            </StyledAudioModalCloseButton>
            <div className="iconContainer">
              <Icon name="karinagrant" />
            </div>
            <StyledSingleAudioTitle className="audio-header-title">Morning Daily Intention</StyledSingleAudioTitle>
          </StyledSingleAudioHeader>
          <StyledSingleAudioBody>
            <ReactAudioPlayer
              autoPlay={true}
              audioURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/morning-daily-intention.mp3"
              onPlay={e => analyticClickEvent(action, 'Audio', 'Play: Morning Daily Intention')}
              title="Morning Daily Intention"
            />
          </StyledSingleAudioBody>
        </StyledAudioModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const MorningDailyIntention = props => {
  const {
    id,
    featuredImage,
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (

    <>
      <ModalProvider backgroundComponent={FadingBackground}>
        <FancyModalButton
          id={props.id}
          title={props.title}
          image='/app/images/the-essential-mindfulness-collection/morning-daily-intention.jpg'
        />
      </ModalProvider>
    </>
  )
}

export default MorningDailyIntention