import React from "react"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
 } from "../components/reactPlayer"
const fixture = [
    {
        programName: "Permissioning & Transformation Practitioner",
        type: 'permission-reset-anxiety',
        featuredImage: "/images/permissioning-butterfly.jpg",
        audioFiles: [            
            {
                title: "Session 1",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 1',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-1.mp4"
                                    title="Permissioning Practitioner: Session 1"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 2",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 2',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-2.mp4"
                                    title="Permissioning Practitioner: Session 2"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 3",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 3',
                                session: <ReactVideoPlayer 
                                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-3.mp4"
                                title="Permissioning Practitioner: Session 3"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 4",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 4',
                                session: <ReactVideoPlayer 
                                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-4.mp4"
                                    title="Permissioning Practitioner: Session 4"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 5",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 5',
                                session: <ReactVideoPlayer 
                                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-5.mp4"
                                title="Permissioning Practitioner: Session 5"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 6",
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 6',
                                session: <ReactVideoPlayer 
                                videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permissioning/practitioner/session-6.mp4"
                                title="Permissioning Practitioner: Session 6"
                                />,
                            }
                        ],
                    }
                ]
            },
        ],
    }
]
  
  export default fixture
  




// sound-bell.jpg