import React from "react"
import {
  Section,
} from "../styledComponents/section"
import permissionManifesting from "../fixtures/permission-manifesting"
import AudioCard from "./App/audioCard"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
const PermissionManifestingAudio = () => {
  const audio = permissionManifesting

    return (
        <>
          <Section>
            <StyledAudio className='single single-audio'>
              {
                    <AudioCard 
                      key={audio[0].title + `-1`}
                      title={audio[0].title}
                      image={audio[0].image}
                      audioMp3={audio[0].audioMp3}
                      duration={audio[0].duration}
                    />     
              }
            </StyledAudio>
          </Section>
        </>
    )
}

export default PermissionManifestingAudio