import React from "react"
import {
    ReactVideoPlayer
 } from "../components/reactPlayer"
const fixture = [
    {
      programName: "Permission to Reset your Nervous System",
        type: 'spoon-therapy',
        audioFiles: [            
            {
                title: 'Session 1 - July 14th 2023',
                image: "/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 1',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permission-to-reset-nervous-system/july-2023-online-workshop/Permission+to+Reset+Your+Nervous+System+-+Session+1.mp4"
                                    title="Permission to Reset your Nervous System: Session 1"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: 'Session 2 - July 24th 2023',
                image: "/images/permissioning-buttefly.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 2',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/permission-to-reset-nervous-system/july-2023-online-workshop/Permission+to+Reset+Your+Nervous+System+-+Session+2.mp4"
                                    title="Permission to Reset your Nervous System: Session 2"
                                />,
                            }
                        ],
                    }
                ]
            },
        ],
    }
]
  
export default fixture