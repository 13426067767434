import React from "react"
import {
  Section,
} from "../styledComponents/section"
import spoonTherapyVideo from "../fixtures/spoon-therapy"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
import AudioCardPermissionResetNervousSystem from "./App/audioCardPermissionResetNervousSystem"
import AudioPatPractitionerCard from "./App/audioPatPractitionerCard"
import VideoSpoonTherapyCard from "./App/videoSpoonTherapyCard"

const SpoonTherapyVideo = () => {
  const st = spoonTherapyVideo
    return (
        <>
          <Section>
            <StyledAudio type="program">
              {
                st[0].audioFiles.map(audio => {
                  return (
                    <VideoSpoonTherapyCard 
                      key={audio.title + `-1`}
                      title={audio.title}
                      image={audio.image}
                      content={audio.content}
                      color={audio.color}
                      audioMp3={audio.audioMp3}
                      duration={audio.duration}
                    />     
                  )
                })
              }
            </StyledAudio>
          </Section>
        </>
    )
}

export default SpoonTherapyVideo