import React from "react"
import {
  Section,
} from "../styledComponents/section"
import permissionResetNervousSystem from "../fixtures/permission-to-reset-nervous-system-online"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
import AudioCardPermissionResetNervousSystem from "./App/audioCardPermissionResetNervousSystem"
const PermissionResetNervousSystemAudio = () => {
  const sleep = permissionResetNervousSystem
    return (
        <>
          <Section>
            <StyledAudio type="program">
              {
                sleep[0].audioFiles.map(audio => {
                  return (
                    <AudioCardPermissionResetNervousSystem 
                      key={audio.title + `-1`}
                      title={audio.title}
                      image={audio.image}
                      content={audio.content}
                      color={audio.color}
                      audioMp3={audio.audioMp3}
                      duration={audio.duration}
                    />     
                  )
                })
              }
            </StyledAudio>
          </Section>
        </>
    )
}

export default PermissionResetNervousSystemAudio