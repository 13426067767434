import React from "react"
import {
  Section,
} from "../styledComponents/section"
import commandHealingVideo from "../fixtures/command-healing"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
import VideoCommandHealingCard from "./App/videoCommandHealingCard"
import CommandHealingDownloadFiles from "./commandHealingDownloadFiles"

const CommandHealingVideo = () => {
  const st = commandHealingVideo
    return (
        <>
          <Section>
            <StyledAudio type="program">
              {
                st[0].audioFiles.map(audio => {
                  return (
                    <VideoCommandHealingCard 
                      key={audio.title + `-1`}
                      title={audio.title}
                      image={audio.image}
                      content={audio.content}
                      color={audio.color}
                      audioMp3={audio.audioMp3}
                      duration={audio.duration}
                    />     
                  )
                })
              }
            </StyledAudio>
            <CommandHealingDownloadFiles />
            
          </Section>
        </>
    )
}

export default CommandHealingVideo